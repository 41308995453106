import HTTPService from '@/api/http/HTTPService'

type TypePayload = {
  dateStart: string
  dateEnd: string
}

export default {
  getAnalyticsCallibri: (siteSlug: string, payload: TypePayload) => HTTPService.get(`site/${siteSlug}/analytics/callibri`, {
    date_begin: payload.dateStart,
    date_end: payload.dateEnd,
  }),
}
