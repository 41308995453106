import HTTPService from '@/api/http/HTTPService'
import TemplateInterface from './TemplateInterface'

type getOptions = {
  type: string;
  search?: string;
  page?: number;
  limit?: number;
  parent_slug?: string;
  use_parent_strict_constraint?: boolean;
}

export default {
  getAll: (siteSlug: string, templateType: string) => HTTPService.get(`site/${siteSlug}/template${templateType ? `?type=${templateType}` : ''}`),
  getOne: (siteSlug: string, templateSlug: string) => HTTPService.get(`site/${siteSlug}/template/${templateSlug}`),
  update: (siteSlug: string, templateSlug: string, payload: TemplateInterface) => HTTPService.put(`site/${siteSlug}/template/${templateSlug}`, payload),
  checkRoot: (siteSlug: string) => HTTPService.get(`site/${siteSlug}/template/check-root`),
  search: (siteSlug: string, options: getOptions) => {
    const params = {
      type: options.type,
      search: options.search,
      parent_slug: options.parent_slug,
      page: options.page || 1,
      limit: options.limit || 20,
      // eslint-disable-next-line no-nested-ternary
      use_parent_strict_constraint: typeof options.use_parent_strict_constraint === 'undefined' ? 1 : options.use_parent_strict_constraint ? 1 : 0,
    }
    return HTTPService.get(`site/${siteSlug}/template/search`, params)
  },
}
