import axios from 'axios'

const token = 'y0_AgAAAABjWfDiAAtbOQAAAAD8XcHuAACwH1qT1CJAOLAE1u-RZQAqzwdU_g'

const createHttpService = () => {
  const service = axios.create({
    baseURL: 'https://api-metrika.yandex.net',
    headers: {
      'Content-type': 'application/json',
      Authorization: `OAuth ${token}`,
    },
  })

  const handleSuccess = response => response.data

  const handleError = error => error?.response?.data

  service.interceptors.response.use(handleSuccess, handleError)

  const get = (url, params = {}) => service.get(url, { params })

  return {
    get,
  }
}

export const yaHttpService = createHttpService()
