import Template from '@/api/http/models/template/Template'

export default {
  namespaced: true,
  state: {
    alerts: [],
  },
  getters: {
    templatesAlerts: state => state.alerts,
  },
  mutations: {
    UPDATE_ALERTS(state, alerts) {
      state.alerts = alerts
    },
  },
  actions: {
    async FETCH_ALERTS(ctx) {
      const currentSite = ctx.rootGetters['user/currentSite']

      if (currentSite.slug) {
        const response = await Template.checkRoot(currentSite.slug)

        if (response.data) {
          ctx.commit('UPDATE_ALERTS', response.data)
        }
      }
    },
  },
}
