export default {
  options: [
    {
      value: 'today',
      label: 'Сегодня',
    },
    {
      value: 'yesterday',
      label: 'Вчера',
    },
    {
      value: 'week',
      label: 'Неделя',
    },
    {
      value: 'month',
      label: 'Месяц',
    },
    {
      value: 'quarter',
      label: 'Квартал',
    },
    {
      value: 'year',
      label: 'Год',
    },
  ],
}
