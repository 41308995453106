// API
export const HTTP_API_BASE_URL = process.env.VUE_APP_HTTP_API_BASE_URL
export const SECRET_HEADER_KEY = process.env.VUE_APP_SECRET_HEADER_KEY || ''
export const SECRET_HEADER_VALUE = process.env.VUE_APP_SECRET_HEADER_VALUE || ''

// TITLE
export const SHOW_TITLE_PREFIX = process.env.VUE_APP_SHOW_PREFIX_TITLE || true
export const TITLE_PREFIX = process.env.VUE_APP_TITLE_PREFIX || 'Yolker'

export default {
  // API
  HTTP_API_BASE_URL,
  SECRET_HEADER_KEY,
  SECRET_HEADER_VALUE,

  // TITLE
  SHOW_TITLE_PREFIX,
  TITLE_PREFIX,
}
