import Auth from '@/api/http/models/auth/Auth'

export default {
  namespaced: true,
  state: {
    user: {},
    redirect: '',
  },
  getters: {
    currentUser: state => state.user,
    isAuth: state => Object.keys(state.user).length > 0,
    isEmailVerified: state => state.user.email_verified,
    isAdmin: state => state.user.is_admin,
    hasSite: state => state.user.sites && state.user.sites.length > 0,
    currentSite: (state, getters) => (getters.hasSite ? state.user.sites[0] : false),
    siteStatus: (state, getters) => (getters.hasSite ? state.user.sites[0].status : false),
  },
  mutations: {
    UPDATE_CURRENT_USER(state, newUser) {
      state.user = newUser
    },
    UPDATE_CURRENT_SITE(state, newSite) {
      state.user.sites.splice(0, 1, newSite)
    },
  },
  actions: {
    async FETCH_CURRENT_USER(ctx) {
      const response = await Auth.me()
      if (response.data && response.message !== 'Unauthenticated.') {
        ctx.commit('UPDATE_CURRENT_USER', response.data)
      }
    },
  },
}
