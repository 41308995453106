import moment from 'moment'
import {
  COLORS,
  DATE_RAW_FORMAT,
  DICTIONARY_METRIKA_CALLIBRI_CATEGORIES,
} from './constants'

const createGroupItem = payload => ({
  id: payload.id,
  name: payload.name || '',
  color: payload.color || '',
  level: payload.level || 0,
  data: payload.data || [],
  children: payload.children || [],
})

export const getColor = index => {
  if (COLORS[index]) {
    return COLORS[index]
  }
  return getColor(index - COLORS.length)
}

export const getListIds = items => items.flatMap(item => ([
  item.id,
  ...getListIds(item.children),
]))

export const prepareRequest = (payload = {}) => {
  const yandexCounterId = payload.yandexCounterId || ''
  const date = payload.date || []
  const group = payload.group || 'day'
  const dimensions = payload.dimensions || ['ym:s:TrafficSource']
  const metrics = payload.metrics || ['ym:s:users']
  const filters = payload.filters || []

  return {
    ids: `${yandexCounterId}`,
    preset: 'sources_summary',
    lang: 'ru',
    date1: date[0],
    date2: date[1],
    group,
    metrics: metrics.join(','),
    dimensions: dimensions.join(','),
    filters: filters.join(','),
  }
}

export const prepareResponse = (dates = [], data = []) => {
  const createGroup = (item, level = 0) => {
    const childrenList = data[level + 1] || []

    const children = childrenList
      .filter(subItem => subItem.id.includes(item.id))
      .map(subItem => createGroup(subItem, level + 1))

    return createGroupItem({
      id: item.id,
      name: item.name,
      level,
      data: dates.map((date, index) => {
        const visits = item.metrics[0]?.[index] || 0
        const requests = item.metrics[1]?.[index] || 0
        return {
          date,
          visits,
          requests,
          calls: 0,
        }
      }),
      children,
    })
  }

  return data[0].map(item => createGroup(item))
}

export const groupCallibriData = (dates = [], data = []) => {
  const categories = data.reduce((acc, item) => {
    const categoriesPack = []

    const filterCategories = DICTIONARY_METRIKA_CALLIBRI_CATEGORIES.filter(category => {
      if ('callibriSource' in category) {
        return (
          category.callibriTrafficType === item.traffic_type
          && category.callibriSource === item.source
        )
      }
      return category.callibriTrafficType === item.traffic_type
    })

    if (filterCategories.length) {
      filterCategories.forEach(category => {
        categoriesPack.push(category)
      })
    } else if (item.traffic_type) {
      categoriesPack.push({
        yandexCategory: item.traffic_type,
        callibriTrafficType: item.traffic_type,
        name: item.traffic_type,
      })
    } else {
      categoriesPack.push({
        yandexCategory: 'unknown',
        callibriTrafficType: null,
        name: 'Не определено',
      })
    }

    categoriesPack.forEach(category => {
      const index = acc.findIndex(cat => cat.yandexCategory === category.yandexCategory)
      if (index === -1) {
        acc.push(category)
      }
    })

    return acc
  }, [])

  return categories
    .map(category => {
      const filterCategories = data.filter(item => {
        if ('callibriSource' in category) {
          return (
            category.callibriTrafficType === item.traffic_type
            && category.callibriSource === item.source
          )
        }
        if (category.callibriTrafficType) {
          return category.callibriTrafficType === item.traffic_type
        }
        return !item.traffic_type
      })

      const values = dates.map((date, index) => {
        const nextDate = dates[index + 1]
        const fromDate = moment(date, DATE_RAW_FORMAT)
        const toDate = nextDate ? moment(nextDate, DATE_RAW_FORMAT) : null

        const calls = filterCategories.filter(item => {
          const momentDate = moment(item.date)
            .utcOffset(0, true)
            .set({
              hour: 0, minute: 0, second: 0, millisecond: 0,
            })

          if (toDate) {
            return (
              momentDate.diff(fromDate) >= 0
              && momentDate.diff(toDate) < 0
            )
          }

          return momentDate.diff(fromDate) >= 0
        }).length

        return {
          date,
          calls,
          visits: 0,
          requests: 0,
        }
      })

      return createGroupItem({
        id: category.yandexCategory,
        name: category.name,
        data: values,
      })
    })
}
