import Vue from 'vue'
import Master from '@/api/http/models/master/Master'
import AdminFeedback from '@/api/http/models/admin/feedback/AdminFeedbackTemplate'
import data from './data'

/* eslint-disable */
Date.prototype.addDays = function(days) {
  let date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date;
}

Date.prototype.removeDays = function(days) {
  let date = new Date(this.valueOf())
  date.setDate(date.getDate() - days)
  return date;
}

Date.prototype.removeMonths = function(months) {
  let date = new Date(this.valueOf())
  date.setMonth(date.getMonth() - months)
  return date;
}
/* eslint-enable */

export default {
  namespaced: true,
  state: {
    locations: {},
    masters: {},
    stats: {},
    tab: 'month',
    date: [new Date().removeMonths(1), new Date()],
    options: data.options,
    links: [],
    templates: [],
  },
  getters: {
    getLocations: state => state.locations,
    getMasters: state => state.masters,
    getTab: state => state.tab,
    getOptions: state => state.options,
    getDate: state => state.date,
    getStats: state => state.stats,
    getClients: state => state.clients,
    getLinks: state => state.links,
    getTemplates: state => state.templates,
  },
  mutations: {
    UPDATE_LOCATIONS(state, newLocations) {
      state.locations = newLocations
    },
    CLEAR_LOCATIONS(state) {
      state.locations = {}
    },
    UPDATE_STATS(state, newStats) {
      state.stats = newStats
    },
    SET_TAB(state, newTab) {
      state.tab = newTab
    },
    SET_DATE(state, newDate) {
      state.date = newDate
    },
    UPDATE_CLIENTS(state, newClient) {
      state.masters.forEach(master => {
        if (master.id === newClient.id) {
          Vue.set(master, 'clients', newClient.data)
        }
      })
    },
    UPDATE_MASTERS(state, newMaster) {
      state.locations.forEach(location => {
        if (location.id === newMaster.id) {
          Vue.set(location, 'masters', newMaster.data)
        }
      })
    },
    SET_LINKS(state, newLinks) {
      state.links = newLinks
    },
    SET_TEMPLATES(state, newTemplates) {
      state.templates = newTemplates
    },
  },
  actions: {
    async fetchLocations(ctx) {
      ctx.commit('CLEAR_LOCATIONS')
      const response = await Master.getAllStats(ctx.state.tab, ctx.state.date[0], ctx.state.date[1])
      if (response.data && response.message !== 'Unauthenticated.') {
        ctx.commit('UPDATE_LOCATIONS', response.data)
        ctx.commit('UPDATE_STATS', response.statistic)
      }
    },
    async fetchClients(ctx, ids) {
      const response = await Master.getClients(ids.masterId, ids.siteContactId, ctx.state.tab, ctx.state.date)
      return response.data
    },
    async fetchMasters(ctx, id) {
      const response = await Master.getMasters(id, ctx.state.tab, ctx.state.date)
      if (response.data && response.message !== 'Unauthenticated.') {
        ctx.commit('UPDATE_MASTERS', { data: response.data, id })
      }
    },
    async fetchLocationMasters(ctx, id) {
      const response = await Master.getMasters(id, ctx.state.tab, ctx.state.date)
      return response.data
    },
    async fetchLinks(ctx) {
      const response = await Master.getLinks()

      if (response.data && response.message !== 'Unauthenticated.') {
        ctx.commit('SET_LINKS', response.data)
      }
    },
    async changeLinks({ dispatch }, links) {
      const filteredLinks = links.filter(link => link.url !== '' && link.url !== null)
      await Master.changeLinks(filteredLinks)

      dispatch('fetchLinks')
    },
    async fetchTemplates(ctx) {
      const response = await AdminFeedback.getTemplates()
      if (response.data && response.message !== 'Unauthenticated.') {
        ctx.commit('SET_TEMPLATES', response.data)
      }
    },
  },
}
