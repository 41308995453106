export default {
  namespaced: true,
  state: {
    templateHoverSlug: '',
  },
  mutations: {
    UPDATE_TEMPLATE_HOVER_SLUG(state, newSlug) {
      state.templateHoverSlug = newSlug
    },
  },
}
