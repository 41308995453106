<template>
  <b-form-group
    :label="label"
    :label-for="id"
  >
    <b-input-group
      class="input-group-merge"
    >
      <b-form-input
        :id="id"
        class="form-control-merge"
        :name="name"
        :value="value"
        :type="passwordFieldType"
        :state="state"
        placeholder="· · · · · · · · · · · ·"
        v-bind="$props"
        @input="$emit('input', $event)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
      <b-input-group-append is-text>
        <feather-icon
          class="cursor-pointer"
          :icon="passwordToggleIcon"
          @click="togglePasswordVisibility"
        />
      </b-input-group-append>
    </b-input-group>
    <small
      v-show="error"
      class="text-danger"
    >{{ error }}</small>
  </b-form-group>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import {
  BFormGroup, BFormInput, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'

export default {
  name: 'InputPassword',
  $_veeValidate: {
    name() {
      return this.name
    },
    value() {
      return this.value
    },
  },
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Пароль',
    },
    error: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: 'password',
    },
    state: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      id: null,
      passwordFieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.id = `password-input-${this._uid}`
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>
