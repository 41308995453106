import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import masters from './masters'
import templates from './templates'
import templatesCheck from './templates-check'
import analytics from './analytics'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    templates,
    templatesCheck,
    masters,
    analytics,
  },
  strict: process.env.DEV,
})
