import HTTPService from '@/api/http/HTTPService'
import FeedbackTemplateInterface from './FeedbackTemplateInterface'

export default {
  getCompanies: () => HTTPService.get('/admin/company'),
  getTemplates: () => HTTPService.get('/admin/feedback/template'),
  getTemplate: (id: number) => HTTPService.get(`/admin/feedback/template/${id}`),
  update: (id: number, payload: FeedbackTemplateInterface) => HTTPService.put(`/admin/feedback/template/${id}`, payload),
  getTemplateVariables: () => HTTPService.get('/admin/feedback/template/variables'),
}
