import HTTPService from '@/api/http/HTTPService'
import URLSearchParams from '@/@core/utils/getUrlParams'
import MasterInterface from './MasterInterface'

export default {
  getAll: (siteSlug: string, query?: {page?: number}) => {
    const queryString = query ? URLSearchParams(query) : false
    return HTTPService.get(`/feedback/master${queryString ? `?${queryString}` : ''}`)
  },
  getAllStats: (filter_type: string, from: any, to: any) => HTTPService.get('/feedback/conversions/location', {
    filter_type,
    from,
    to,
  }),
  getMasters: (site_contact_id: number, filter_type: string, date: Array<string[]>) => HTTPService.get('/feedback/conversions/master', {
    filter_type,
    from: date[0],
    to: date[1],
    site_contact_id,
  }),
  getClients: (master_id: number, site_contact_id: number, filter_type: string, date: Array<string[]>) => HTTPService.get('feedback/conversions/client', {
    site_contact_id,
    master_id,
    filter_type,
    from: date[0],
    to: date[1],
  }),
  getLinks: () => HTTPService.get('feedback/url'),
  changeLinks: (urls: Array<{ type: string, url: string }>) => HTTPService.post('feedback/url', {
    urls,
  }),
  confirmReview: (id: number) => HTTPService.post(`feedback/conversions/${id}/accept`),
  add: (payload: MasterInterface) => HTTPService.post('/feedback/master', payload),
  getOne: (master: number) => HTTPService.get(`/feedback/master/${master}`),
  update: (master: number, payload: MasterInterface) => HTTPService.put(`/feedback/master/${master}`, payload),
  delete: (master: string) => HTTPService.delete(`/feedback/master/${master}`),
}
