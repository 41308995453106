import moment from 'moment'
import Site from '@/api/http/models/site/site/Site'
import Analytics from '@/api/http/models/analytics/Analytics'
import { yaHttpService } from '@/api/yaHttp/yaHttpService'
import { DATE_RAW_FORMAT, TOTAL_COLOR } from './constants'
import {
  prepareRequest,
  prepareResponse,
  groupCallibriData,
  getColor,
  getListIds,
} from './helpers'

export default {
  namespaced: true,
  state: {
    date: [],
    checkboxes: [],
    data: [],
    totalCheckbox: false,
    loading: false,
    loadingMetrika: false,
    loadingCallibri: false,
    yandexCounterId: '',
    yandexGoalId: '',
    analyticsCallibriId: null,
    isApproximateCalls: false,
  },
  getters: {
    GET_DATE(state) {
      return state.date
    },
    GET_CHECKBOXES(state) {
      return state.checkboxes
    },
    GET_TOTAL_CHECKBOX(state) {
      return state.totalCheckbox
    },
    GET_DIFF_DAYS(state) {
      if (!state.date[0] || !state.date[1]) {
        return 0
      }
      const m1 = moment(state.date[0], DATE_RAW_FORMAT)
      const m2 = moment(state.date[1], DATE_RAW_FORMAT)
      return m2.diff(m1, 'days', true)
    },
    GET_DIFF_MONTHS(state) {
      if (!state.date[0] || !state.date[1]) {
        return 0
      }
      const m1 = moment(state.date[0], DATE_RAW_FORMAT)
      const m2 = moment(state.date[1], DATE_RAW_FORMAT)
      return m2.diff(m1, 'months', true)
    },
    GET_LOADING(state) {
      return state.loading
    },
    GET_LOADING_METRIKA(state) {
      return state.loadingMetrika
    },
    GET_LOADING_CALLIBRI(state) {
      return state.loadingCallibri
    },
    IS_CHECKED(state) {
      return id => state.checkboxes.includes(id)
    },
    GET_DATA(state) {
      return state.data
    },
    GET_DATA_ITEM(state) {
      return id => {
        const search = (items, itemId) => items.reduce((acc, item) => {
          if (acc) {
            return acc
          }
          if (item.id === itemId) {
            return item
          }
          return search(item.children, itemId)
        }, null)

        return search(state.data, id)
      }
    },
    HAS_CHECKED_CHILDREN(_, getters) {
      return dataItem => {
        if (!dataItem.children.length) {
          return false
        }

        const checkedChildren = dataItem.children.filter(child => getters.IS_CHECKED(child.id))
        if (checkedChildren.length) {
          return true
        }

        return Boolean(dataItem.children.map(child => getters.HAS_CHECKED_CHILDREN(child)).filter(Boolean).length)
      }
    },
    GET_DATA_PARENT_ITEM(state) {
      return id => {
        const search = (items, itemId) => items.reduce((acc, item) => {
          if (acc) {
            return acc
          }
          if (item.children.find(child => child.id === itemId)) {
            return item
          }
          return search(item.children, itemId)
        }, null)

        return search(state.data, id)
      }
    },
    GET_DATA_TOTAL(state) {
      const dates = state.data[0]
        ? state.data[0].data.map(item => item.date)
        : []

      return {
        id: 'total',
        name: 'Итого',
        color: TOTAL_COLOR,
        level: 0,
        children: [],
        data: dates.map((_, index) => state.data.reduce((acc, dataItem) => {
          if (!acc) {
            return dataItem.data[index]
          }
          const numberKeys = Object.entries(dataItem.data[index])
            .filter(([key, value]) => key && typeof value === 'number')
            .map(([key, value]) => [
              key,
              value + acc[key],
            ])
          return {
            ...acc,
            ...Object.fromEntries(numberKeys),
          }
        }, null)),
      }
    },
    GET_DATA_FLAT(state) {
      const flatting = dataItem => {
        if (!dataItem.children || !dataItem.children.length) {
          return []
        }
        return [
          ...dataItem.children,
          ...dataItem.children.flatMap(child => flatting(child)),
        ]
      }

      return state.data.flatMap(dataItem => ([
        dataItem,
        ...flatting(dataItem),
      ]))
    },
    GET_DATA_FLAT_FILTERED(state, getters) {
      return getters.GET_DATA_FLAT.filter(dataItem => state.checkboxes.includes(dataItem.id))
    },
    GET_YANDEX_COUNTER_ID(state) {
      return state.yandexCounterId
    },
    GET_YANDEX_GOAL_ID(state) {
      return state.yandexGoalId
    },
    GET_ANALYTICS_CALLIBRI_ID(state) {
      return state.analyticsCallibriId
    },
    GET_IS_APPROXIMATE_CALLS(state) {
      return state.isApproximateCalls
    },
  },
  mutations: {
    SET_DATE(state, payload = []) {
      state.date = payload
    },
    SET_DATA(state, payload = []) {
      state.data = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_LOADING_METRIKA(state, payload) {
      state.loadingMetrika = payload
    },
    SET_LOADING_CALLIBRI(state, payload) {
      state.loadingCallibri = payload
    },
    SET_CHECKBOXES(state, payload) {
      state.checkboxes = payload
    },
    SET_TOTAL_CHECKBOX(state, payload) {
      state.totalCheckbox = payload
    },
    SET_YANDEX_COUNTER_ID(state, payload) {
      state.yandexCounterId = payload
    },
    SET_YANDEX_GOAL_ID(state, payload) {
      state.yandexGoalId = payload
    },
    SET_ANALYTICS_CALLIBRI_ID(state, payload) {
      state.analyticsCallibriId = payload
    },
    SET_IS_APPROXIMATE_CALLS(state, payload) {
      state.isApproximateCalls = payload
    },
  },
  actions: {
    async FETCH_DATA({ getters, rootGetters, commit }, payload) {
      const { date, detailing } = payload
      const siteSlug = rootGetters['user/currentSite']?.slug

      const fetchYandexMetrikaData = async () => {
        try {
          const response1 = await yaHttpService.get('/stat/v1/data/bytime', prepareRequest({
            yandexCounterId: getters.GET_YANDEX_COUNTER_ID,
            group: detailing,
            date,
            metrics: [
              'ym:s:visits',
              `ym:s:goal${getters.GET_YANDEX_GOAL_ID}reaches`,
            ],
            dimensions: [
              'ym:s:lastsignTrafficSource',
            ],
            filters: [
              'ym:s:isRobot==\'No\'',
            ],
          }))

          const response2 = await yaHttpService.get('/stat/v1/data/bytime', prepareRequest({
            yandexCounterId: getters.GET_YANDEX_COUNTER_ID,
            group: detailing,
            date,
            metrics: [
              'ym:s:visits',
              `ym:s:goal${getters.GET_YANDEX_GOAL_ID}reaches`,
            ],
            dimensions: [
              'ym:s:lastsignSourceEngine',
            ],
            filters: [
              'ym:s:isRobot==\'No\'',
            ],
          }))

          if (!response1.data || !response2.data) {
            throw Error()
          }

          const dates = response1.time_intervals.map(item => item[0])

          const data1 = response1.data.map(item => ({
            id: item.dimensions[0].id,
            name: item.dimensions[0].name,
            metrics: item.metrics,
          }))

          const data2 = response2.data.map(item => ({
            id: item.dimensions[0].id,
            name: item.dimensions[0].name,
            metrics: item.metrics,
          }))

          return {
            data: prepareResponse(dates, [
              data1,
              data2,
            ]),
            isSuccess: true,
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('ERROR! ANALYTICS_FETCH_METRIKA_DATA: ', e)
          return {
            data: [],
            isSuccess: false,
          }
        }
      }

      const fetchCallibriData = async dates => {
        try {
          if (!getters.GET_ANALYTICS_CALLIBRI_ID) {
            throw Error
          }

          const { data } = await Analytics.getAnalyticsCallibri(siteSlug, {
            dateStart: date[0],
            dateEnd: date[1],
          })

          if (!data) {
            throw Error
          }

          const calls = data
            .flatMap(item => item.calls)
            .filter(item => item.conversations_number === 1)

          return {
            data: groupCallibriData(dates, calls),
            isSuccess: true,
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('ERROR! ANALYTICS_FETCH_CALLIBRI_DATA: ', e)
          return {
            data: [],
            isSuccess: false,
          }
        }
      }

      const mergeData = (data1, data2) => {
        const updateCategory = categoryItem => {
          const children = categoryItem.children.map(child => updateCategory(child))
          const mergeCategory = data2.find(category => category.id === categoryItem.id)

          const newData = (() => {
            if (!mergeCategory) {
              return categoryItem.data
            }
            return categoryItem.data.map((dataItem, index) => {
              const numberKeys = Object.entries(dataItem)
                .filter(([key, value]) => key && typeof value === 'number')
                .map(([key, value]) => [
                  key,
                  value + mergeCategory.data[index][key],
                ])

              return {
                ...dataItem,
                ...Object.fromEntries(numberKeys),
              }
            })
          })()

          return {
            ...categoryItem,
            data: newData,
            children,
          }
        }

        const updatedData = data1.map(item => updateCategory(item))

        const ids = getListIds(data1)

        data2.forEach(dataItem => {
          if (!ids.includes(dataItem.id)) {
            const parentCategoryName = dataItem.id.split('.')[0]
            const findParent = updatedData.find(item => item.id === parentCategoryName)
            if (findParent) {
              findParent.children.push(dataItem)
            } else {
              updatedData.push(dataItem)
            }
          }
        })

        return updatedData
      }

      const calculateApproximateCalls = data => {
        const calculate = dataItem => {
          if (dataItem.children && dataItem.children.length) {
            const children = dataItem.children.map(child => calculate(child))

            return {
              ...dataItem,
              children,
              data: dataItem.data.map((item, index) => {
                const calls = children.reduce((acc, child) => acc + child.data[index].calls, 0)

                return {
                  ...item,
                  calls,
                }
              }),
            }
          }

          return {
            ...dataItem,
            data: dataItem.data.map(item => {
              const visits = +item.visits || 0
              const requests = +item.requests || 0
              const calls = Math.round(requests * 3 + visits * 0.005 * (1 - ((requests ** 10) / ((requests ** 10) + 1))))

              return {
                ...item,
                calls,
              }
            }),
          }
        }

        return data.map(dataItem => calculate(dataItem))
      }

      const setColor = (data, index = 0) => data.map((dataItem, i) => {
        const calcIndex = index + i
        return {
          ...dataItem,
          color: getColor(calcIndex),
          children: setColor(dataItem.children, index + 1),
        }
      })

      commit('SET_LOADING', true)

      commit('SET_LOADING_METRIKA', true)

      const {
        data: yandexMetrikaData,
        isSuccess: yandexMetrikaIsSuccess,
      } = await fetchYandexMetrikaData()

      commit('SET_LOADING_METRIKA', false)

      if (!yandexMetrikaIsSuccess) {
        return
      }

      const dates = yandexMetrikaData[0].data
        ? yandexMetrikaData[0].data.map(item => item.date)
        : []

      commit('SET_LOADING_CALLIBRI', true)

      const {
        data: callibriData,
        isSuccess: callibriIsSuccess,
      } = await fetchCallibriData(dates)

      commit('SET_LOADING_CALLIBRI', false)

      const mergedData = callibriIsSuccess
        ? mergeData(yandexMetrikaData, callibriData)
        : calculateApproximateCalls(yandexMetrikaData)

      const mergedColorData = setColor(mergedData)

      const checkboxes = mergedColorData.map(item => item.id)

      commit('SET_IS_APPROXIMATE_CALLS', !callibriIsSuccess)
      commit('SET_DATE', date)
      commit('SET_DATA', mergedColorData)
      commit('SET_CHECKBOXES', checkboxes)
      commit('SET_LOADING', false)
    },
    TOGGLE_CHECKBOX({ commit, getters }, id) {
      const dataItem = getters.GET_DATA_ITEM(id)
      const dataItemChildren = (() => {
        const search = item => {
          if (!item.children || !item.children.length) {
            return []
          }
          return [
            ...item.children,
            ...item.children.flatMap(i => search(i)),
          ]
        }

        return search(dataItem)
      })()
      const dataItemParents = (() => {
        const search = itemId => {
          const parent = getters.GET_DATA_PARENT_ITEM(itemId)
          if (!parent) {
            return []
          }
          return [
            parent,
            ...search(parent.id),
          ]
        }

        return search(id)
      })()

      if (getters.IS_CHECKED(id)) {
        const excludeIds = [
          id,
        ]

        const checkboxes = [
          ...getters.GET_CHECKBOXES,
        ].filter(item => !excludeIds.includes(item))

        commit('SET_CHECKBOXES', checkboxes)
      } else {
        const includeIds = [
          id,
        ]

        const excludeIds = [
          ...dataItemParents.map(item => item.id),
          ...dataItemChildren.map(item => item.id),
        ]

        const checkboxes = [
          ...getters.GET_CHECKBOXES,
          ...includeIds,
        ].filter(item => !excludeIds.includes(item))

        commit('SET_CHECKBOXES', checkboxes)
      }
    },
    TOGGLE_TOTAL_CHECKBOX({ commit, getters }) {
      commit('SET_TOTAL_CHECKBOX', !getters.GET_TOTAL_CHECKBOX)
    },
    async FETCH_SETTINGS({ rootGetters, commit }) {
      const siteSlug = rootGetters['user/currentSite']?.slug

      const { data } = await Site.getOne(siteSlug)

      const yandexCounterId = data?.settings['analytics.yandex.counter_id']
      const yandexGoalId = data?.settings['analytics.yandex.target_id']
      const analyticsCallibriId = data?.settings['analytics.callibri.id']

      commit('SET_YANDEX_COUNTER_ID', yandexCounterId)
      commit('SET_YANDEX_GOAL_ID', yandexGoalId)
      commit('SET_ANALYTICS_CALLIBRI_ID', analyticsCallibriId)
    },
  },
}
