import axios from 'axios'
import config from '../../../config'

class HTTPService {
  constructor() {
    if (!this.instance) this.instance = this
    this.config = config
    this.service = this.initService()
    return this.instance
  }

  initService() {
    const headers = { Accept: 'application/json' }
    if (this.config.SECRET_HEADER_KEY && this.config.SECRET_HEADER_VALUE) {
      headers[this.config.SECRET_HEADER_KEY] = this.config.SECRET_HEADER_VALUE
    }
    const service = axios.create({
      baseURL: this.config.HTTP_API_BASE_URL,
      withCredentials: true,
      headers,
    })

    service.interceptors.response.use(this.handleSuccess, this.handleError)
    return service
  }

  handleSuccess = response => response.data

  handleError = error => error?.response?.data

  get(path, params = {}) {
    return this.service.get(path, { params })
  }

  patch(path, payload) {
    return this.service.patch(path, payload)
  }

  post(path, payload) {
    return this.service.post(path, payload)
  }

  put(path, payload) {
    return this.service.put(path, payload)
  }

  delete(path, payload) {
    return this.service.delete(path, {
      data: payload,
    })
  }

  file(path, file) {
    return this.service.post(path, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}

export default new HTTPService()
