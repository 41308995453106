import HTTPService from '@/api/http/HTTPService'
import UserInterface from './UserInterface'
import EmailInterface from './EmailInterface'
import ResetInterface from './ResetInterface'
import UpdateUserInterface from './UpdateUserInterface'

export default {
  getCsrfCookie: () => HTTPService.get('auth/csrf-cookie'),
  registration: (payload: UserInterface) => HTTPService.post('auth/register', payload),
  verify: (base64URL: string) => HTTPService.get(atob(base64URL)),
  verifyResend: () => HTTPService.post('auth/verify/resend'),
  me: () => HTTPService.get('auth/me'),
  update: (payload: UpdateUserInterface) => HTTPService.put('auth/me', payload),
  login: (payload: UserInterface) => HTTPService.post('auth/login', payload),
  logout: () => HTTPService.post('auth/logout'),
  forgot: (payload: EmailInterface) => HTTPService.post('auth/password/forgot', payload),
  reset: (payload: ResetInterface) => HTTPService.post('auth/password/reset', payload),
}
