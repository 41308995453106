export const DATE_RAW_FORMAT = 'YYYY-MM-DD'

export const DATE_TIME_RAW_FORMAT = 'YYYY-MM-DD HH:mm'

export const DATE_FORMAT = 'DD.MM.YYYY'

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

export const COLORS = [
  '#97cd64',
  '#ffd963',
  '#fd5a3e',
  '#78b6e8',
  '#a955b8',
  '#dc9d6b',
  '#ea5380',
  '#4669bc',
  '#4aa162',
]

export const TOTAL_COLOR = '#4c4c4c'

export const DICTIONARY_METRIKA_CALLIBRI_CATEGORIES = [
  {
    yandexCategory: 'organic',
    callibriTrafficType: 'Переходы из поисковых систем',
  },
  {
    yandexCategory: 'organic.yandex',
    callibriTrafficType: 'Переходы из поисковых систем',
    callibriSource: 'Yandex',
  },
  {
    yandexCategory: 'organic.google',
    callibriTrafficType: 'Переходы из поисковых систем',
    callibriSource: 'Google',
  },
  {
    yandexCategory: 'ad',
    callibriTrafficType: 'Переходы по рекламе',
  },
  {
    yandexCategory: 'ad.Яндекс: Директ',
    callibriTrafficType: 'Переходы по рекламе',
    callibriSource: 'Yandex Direct',
  },
  {
    yandexCategory: 'ad.Другая реклама: определено по меткам',
    callibriTrafficType: 'Переходы по рекламе',
    callibriSource: '',
    name: 'Не определено',
  },
  {
    yandexCategory: 'direct',
    callibriTrafficType: 'Прямые заходы',
  },
  {
    yandexCategory: 'internal',
    callibriTrafficType: 'Внутренние переходы',
  },
  {
    yandexCategory: 'referral',
    callibriTrafficType: 'Переходы по ссылкам на сайтах',
  },
]
